import { useState } from 'react';
import toast from 'react-hot-toast';
import { useErrorHandler } from './useErrorHandler';

/**
 * Executes and action and handles success/error toasts.
 * Optionally supports a loading toast state that will transition to either success or error.
 */
export const useToastAction = (showValidationErrors = false, toastIdFixed) => {
  const errorHandler = useErrorHandler();
  const [isExecuting, setIsExecuting] = useState(false);

  const onExecute = async (action, errorMessage, successMessage, loadingMessage) => {
    let toastId;
    try {
      setIsExecuting(true);
      toastId = toastIdFixed ? toastIdFixed : loadingMessage ? toast.loading(loadingMessage) : null;

      // if (toastId) {
      //   await wait(2000); // left in here if we ever wanted to force simulate a long running action
      // }

      await action();
      if (toastId) {
        if (successMessage) {
          toast.success(successMessage, {
            id: toastId
          });
        } else {
          toast.remove(toastId);
        }
      } else {
        if (successMessage) {
          toast.success(successMessage);
        }
      }
    } catch (error) {
      errorHandler.handleApiError(error, errorMessage, showValidationErrors, toastId);
    } finally {
      setIsExecuting(false);
    }
  };

  return {
    execute: (action, errorMessage, successMessage = null, loadingMessage = null) => {
      onExecute(action, errorMessage, successMessage, loadingMessage);
    },
    executeAsync: async (action, errorMessage, successMessage = null, loadingMessage = null) => {
      await onExecute(action, errorMessage, successMessage, loadingMessage);
    },
    isExecuting
  };
};
