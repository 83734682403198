import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import  { CloudUploadIcon as ServerUploadIcon, UploadIcon as ManualUploadIcon, ArrowCircleRightIcon as FromOnlyIcon, BanIcon as ToOnlyIcon } from '@heroicons/react/outline'

import { useModalHelper } from "@metaforcelabs/metaforce-core";

import { useToastAction } from '../../../hooks/useToastAction';
import { useClassNames } from "../../../hooks/useClassNames";
import { useFeature } from '../../../hooks/useFeature';
import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup';

import { TwTableContext } from '../../../contexts';

import * as Constants from '../../../utils/constants'

import * as InteractApi from "../../../api/interact";
import * as TemplateEnvApi from "../../../api/interactTemplateEnvironment.js";
import * as TemplateFileApi from "../../../api/interactTemplateFile.js";

import FileInfoDialog from "./templateFileinfoDialog";
import TemplateFileTableRow from "./templateFileTableRow"

import { EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"

import { LOGICFILESTATUSES, LOGICFILEORIGINS, getFileStatusesBetweenTemplates } from "../../../utils/LogicFileUtilities";

import Modal from '../../../components/Modal';

import { Button as CmpButton } from "../../../components/Button";
import { Label as CmpLabel } from "../../../components/Label";
import { Checkbox as CmpCheckbox } from "../../../components/Checkbox";
import { Panel as CmpPanel } from "../../../components/Panel";
import MenuContextList from "../../../components/MenuContextList";
import { TwPage } from "../../../components/TailwindPage";
import { TwTable } from "../../../components/TailwindTable";
import GenericModal from '../../../components/Modals/genericModal';
import { doc } from 'prettier';

export default function TemplatesPublish() {
  const { groupId, orderId } = useParams();
  const { command } = useParams();
  const { hasFeature } = useFeature();
  const { dispatchEnableEnvironmentGroupPanelEvent } = useEnvironmentGroup();

  const refMenuContainerTo = useRef();

  const history = useHistory();
  const [initialized, setInitialized] = useState(false)

  const [viewSettings, setViewSettings] = useState(null)

  const [environmentGroup, setEnvironmentGroup] = useState(null)
  const [fromTemplateEnvironment, setFromTemplateEnvironment] = useState([])
  const [toTemplateEnvironment, setToTemplateEnvironment] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [fileStatuses, setFileStatuses] = useState([])

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openShowFileInfo, setOpenShowFileInfo] = useState(false);
  const [fileInfoData, setFileInfoData] = useState(null);

  const [hasCrudAccess, setHasCrudAccess] = useState(false);

  const [showFromEnvironment, setShowFromEnvironment] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isCopying, setIsCopying] = useState(false);

  const { classNames } = useClassNames();
  const loadAction = useToastAction();
  const deleteAction = useToastAction();
  const copyAction = useToastAction();

  const copyToLocalGroupModalHelper = useModalHelper();

  const tableSettings = {
    type: TwTable.types.simple
  }

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const environments = await InteractApi.getInteractEnvironments();
      const envGrp = await InteractApi.getEnvironmentGroup(groupId);
      const tplEnvironments = await TemplateEnvApi.getInteractTemplateEnvironments(groupId);
      const activeTplEnvironments = tplEnvironments?.filter(x => x.active).sort((a, b) => a.order > b.order ? 1 : -1);

      setEnvironmentGroup(envGrp);

      let currOrder = parseInt(orderId);

      var hasCrudAccess = hasFeature(Constants.templatesConfigurationFeatureCode) && !envGrp.isVirtual;
      setHasCrudAccess(hasCrudAccess)

      const showFromEnv = currOrder !== 1 && command !== "fo" && hasCrudAccess;
      setShowFromEnvironment(showFromEnv);

      const toTemplate = activeTplEnvironments.find(({ order }) => order == currOrder);
      var toIdx = activeTplEnvironments.indexOf(toTemplate);

      const fromTemplate = toIdx > 0 && showFromEnv ? activeTplEnvironments[toIdx - 1] : undefined;
      const fromEnvironment = fromTemplate ? environments.find(({ value }) => value === fromTemplate.customerEnvironment) : undefined;
      const fromData = fromTemplate ? {template: fromTemplate, environment: fromEnvironment} : undefined;
      setFromTemplateEnvironment(fromData ?? []);

      const toEnvironment = environments.find(({ value }) => value === toTemplate.customerEnvironment);
      const toData = {template: toTemplate, environment: toEnvironment};
      setToTemplateEnvironment(toData);

      const fileStatus = getFileStatusesBetweenTemplates(fromTemplate, toTemplate, (status) => {
        // if (hasUpdatableStatus(status))
        //   toggleSelectFile(status);
      });

      // console.log("TemplatesPublish", "loadBaseData", "fromData", fromData);
      // console.log("TemplatesPublish", "loadBaseData", "toData", toData);
      // console.log("TemplatesPublish", "loadBaseData", "fileStatus", fileStatus);

      setFileStatuses(fileStatus);
      setInitialized(true);
    }, "Failed to load (TemplatesUpdate)")
  }

  useEffect(() => {
    if (!groupId)
      return;
    dispatchEnableEnvironmentGroupPanelEvent(false);
    loadBaseData();

    return () => dispatchEnableEnvironmentGroupPanelEvent(true);
  }, [groupId]);

  const toggleSelectFile = (file) => {
    setSelectedFiles(prev => {
      const idx = selectedFiles.findIndex(x => x.id === file.id);
      if (idx === -1) {
        return [...prev, file];
      }
      return [...prev.filter(item => item !== file)];
    })
  }

  const hasUpdatableStatus = (fileStatus) => {
    const status = fileStatus.status;
    return status === LOGICFILESTATUSES.fromNewer || status === LOGICFILESTATUSES.fromOnly;
  }

  const hasInvalidStatus = (fileStatus) => {
    const status = fileStatus.status;
    return status === LOGICFILESTATUSES.toNewer || status === LOGICFILESTATUSES.toOnly;
  }

  const getInvalidStatusMessage = (fileStatus) => {
    switch (fileStatus.status)
    {
    case LOGICFILESTATUSES.toNewer:
      return "File is older";
    case LOGICFILESTATUSES.toOnly:
      return "File missing";
    }
    return undefined;
  }

  const publishSelectedFiles = async () => {
    // TODO: Add validation that the objects not been changed...

    await TemplateEnvApi.publishFileBetweenTemplateEnvironments(selectedFiles,
      fromTemplateEnvironment.template, toTemplateEnvironment.template);
    history.goBack();
  }
  
  const downloadFile = async (file) => {
    await TemplateFileApi.downloadInteractTemplateFile(file.id, file.fileName);
  }

  const showProcessManager = async (file, templateEnv) => {
    history.push(`/templates/processes/${groupId}/${templateEnv.id}/${file.id}`);
  }

  const showInformation = async (file) => {
    setFileInfoData({file, toTemplateEnvironment});
    setOpenShowFileInfo(true);
  }

  const openDelete = async (file) => {
    setFileToDelete(file);
    setOpenDeleteConfirmation(true);
  }

  const handleDelete = async () => {
    if (!fileToDelete)
      return;
    deleteAction.execute(async () => {
      const fileId = fileToDelete.id;
      setOpenDeleteConfirmation(false);
      setFileToDelete(null);

      await TemplateFileApi.deleteInteractTemplateFile(fileId);
      await loadBaseData();
    }, "Failed to delete Interact Logic file", "Interact Logic file deleted")
  }

  const openCopyToLocal = (template, file) => {
    // console.log("openCopyToLocal", "template", template, "file", file, "environmentGroup", environmentGroup);
    copyToLocalGroupModalHelper.setActivetItem({template: template, file: file, environmentGroup: environmentGroup});
    copyToLocalGroupModalHelper.open();
  }

  const handleCopyToLocalGroup = async (template, file, localGroup) => {
    // console.log("handleCopyToLocalGroup", "template", template, "file", file, "localGroup", localGroup);

    deleteAction.execute(async () => {
      try {
        setIsCopying(true);
        await TemplateEnvApi.copyFileToLocalGroup(template.id, file.id, localGroup.id);
        await loadBaseData();
      } finally {
        setIsCopying(false);
        copyToLocalGroupModalHelper.setActivetItem(null);
        copyToLocalGroupModalHelper.close();
      }
    }, "Failed to copy Interact Logic file", "Interact Logic file copied")
  }

  const getStatusFileIcon = (file, status) => {
    switch(status)
    {
      case LOGICFILESTATUSES.fromOnly:
        return <FromOnlyIcon className="h-5 w-5" aria-hidden="true" />;
      case LOGICFILESTATUSES.toOnly:
        return <ToOnlyIcon className="h-5 w-5" aria-hidden="true" />;
      }
  }

  const getFileOriginIcon = (file, status) => {
    const statusIcon = getStatusFileIcon(file, status);
    if (!file)
      return statusIcon || <div className='h-5 w-5' />
    if (file.fileOrigin === LOGICFILEORIGINS.manual)
      return <ManualUploadIcon className="h-5 w-5" aria-hidden="true" />
    return <ServerUploadIcon className="h-5 w-5" aria-hidden="true" />
  }

  const getFileActions = (file, templateEnv) => {
    var actions = environmentGroup.isVirtual
     ? []
     : [
      {
        name: "Information",
        onClick: () => showInformation(file?.to),
        disabled: !file?.to ?? true
      }, {
        name: "Manage Workflows",
        onClick: () => showProcessManager(file?.to, templateEnv),
        disabled: !file?.to
      }, {
        type: "divider"
      }, {
        name: "Download",
        onClick: () => downloadFile(file?.to),
        disabled: !file?.to
      }
    ];

    actions.push(
      {
        name: "Copy to local group",
        onClick: () => openCopyToLocal(templateEnv, file?.to),
        disabled: !file?.to ?? true
      }
    );

    if (!environmentGroup.isVirtual)
    {
      actions.push(
        {
          type: "divider"
        }, {
          name: "Remove",
          onClick: () => openDelete(file?.to),
          disabled: !file?.to || !hasCrudAccess
        }
      );
      }
    return actions;
  }

  const fullPanelWidth = true;

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed
            title="Interact Logic file maintainance"
            description={showFromEnvironment 
              ? `This feature will allow you to manage and update Metaforce Logic files from ${fromTemplateEnvironment?.environment?.name?.toUpperCase() || ""} to ${toTemplateEnvironment?.environment?.name?.toUpperCase() || ""}`
              : `This feature will allow you to manage Metaforce Logic files from ${toTemplateEnvironment?.environment?.name?.toUpperCase() || ""}`
            }
          />
        </TwPage.Header>

        {initialized && (
          <>
            <EnvironmentGroupContainerCard
              group={environmentGroup}
              collapsable={false}
            >
              <div className="mt-8 flow-root">
                {/* <div className='flex flex-row place-content-center gap-8'> */}
                <div className={`${fullPanelWidth ? (showFromEnvironment ? "flex-col tplmtn:flex-row" : "flex-col tplmtn:flex-row place-content-center") : "flex-col tplmtn:flex-row place-content-center"} flex gap-4 xl:gap-8`}>
                  {showFromEnvironment &&
                  <CmpPanel additionalClass={`${fullPanelWidth ? "flex-auto" : "flex-none"}`}>
                    <div className="flex justify-end">
                      <CmpLabel text="Environment to update from" additionalClass='text-xs justify-end text-gray-400 dark:text-gray-500 sm:pt-0' />
                    </div>
                    <CmpLabel text={fromTemplateEnvironment?.environment?.name} additionalClass='text-lg font-bold' />
                    <TwTableContext.Provider value={tableSettings}>
                      <TwTable>
                        <TwTable.Head>
                          <TwTable.Header additionalClass="h-14 pl-3 tplmtn:pl-6"/>
                          <TwTable.Header additionalClass={'pl-3 tplmtn:pl-6 uppercase'}>
                            Name
                          </TwTable.Header>
                          <TwTable.Header additionalClass={'pl-3 tplmtn:pl-6 uppercase'}>
                            Updated
                          </TwTable.Header>
                          <TwTable.Header />
                        </TwTable.Head>
                        <TwTable.Body noDivider={true}>
                          {fileStatuses && fileStatuses.map((file, index) => (
                            <TemplateFileTableRow
                              fileName={file.fileName}
                              fileId={file.id}
                              fileData={file.from}
                              status={file.status} 
                              addDivider={index < fileStatuses.length - 1}
                              cbElem={
                                <CmpCheckbox
                                  id="selected"
                                  name="selected"
                                  value={selectedFiles.findIndex(i => i === file) !== -1}
                                  disabled={hasInvalidStatus(file)}
                                  onChange={e => toggleSelectFile(file)}
                                />
                              }
                              statusElem={
                                <>
                                  {hasInvalidStatus(file) &&
                                    <span className={classNames(
                                      "bg-red-700 text-white",
                                      "inline-flex items-center rounded-full px-2 py-1 text-xs font-medium")}
                                    >
                                      {getInvalidStatusMessage(file)}
                                    </span>
                                  }
                                  {hasUpdatableStatus(file) &&
                                    <span className={classNames(
                                      "bg-yellow-100 text-yellow-800",
                                      "inline-flex items-center rounded-full px-2 py-1 text-xs font-medium",
                                      "flex-inline tplmtn:hidden")}
                                    >
                                      Updates available
                                    </span>
                                  }

                                </>
                              }                            
                            />                        
                          ))}
                        </TwTable.Body>
                      </TwTable>
                    </TwTableContext.Provider>
                  </CmpPanel>
                  }

                  {showFromEnvironment &&
                  <div className='flex flex-col mt-0 tplmtn:mt-48'>
                  {/* <div className='flex flex-col place-content-center'> */}
                    <CmpButton
                      variant={CmpButton.variants.primary}
                      // className="flex-none w-32 bg-white text-gray-900 hover:bg-gray-100 hover:bg-text-white dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                      disabled={selectedFiles.length === 0}
                      onClick={publishSelectedFiles}
                    >
                      {`Update ${selectedFiles.length > 0 ? `(${selectedFiles.length})` : ""} >>`}
                    </CmpButton>
                  </div>
                  }

                  <CmpPanel additionalClass={`${fullPanelWidth ? (showFromEnvironment ? "flex-auto" : "flex-none w-2/4" ): "flex-none"}`}>
                    {showFromEnvironment &&
                    <div className="flex justify-end">
                      <CmpLabel text="Environment to update" additionalClass='text-xs justify-end text-gray-400 dark:text-gray-500 sm:pt-0' />
                    </div>
                    }
                    <CmpLabel text={toTemplateEnvironment?.environment?.name} additionalClass='text-lg font-bold' />
                    <TwTableContext.Provider value={tableSettings}>
                      <div ref={refMenuContainerTo}>
                      <TwTable>
                        <TwTable.Head>
                          <TwTable.Header additionalClass={'sm:pl-6 h-14 uppercase'}>
                            Name
                          </TwTable.Header>
                          <TwTable.Header additionalClass={'sm:pl-6 uppercase'}>
                            Updated
                          </TwTable.Header>
                          <TwTable.Header/>
                          <TwTable.Header/>
                        </TwTable.Head>
                        <TwTable.Body noDivider={true}>
                          {fileStatuses && fileStatuses.map((file, index) => (
                            <TemplateFileTableRow
                              fileName={file.fileName}
                              fileId={file.id}
                              fileData={file.to}
                              status={file.status} 
                              addDivider={index < fileStatuses.length - 1}
                              statusElem={
                                <>
                                {hasUpdatableStatus(file) &&
                                  <span className={classNames(
                                    "bg-yellow-100 text-yellow-800",
                                    "inline-flex items-center rounded-full px-2 py-1 text-xs font-medium")}
                                  >
                                    Updates available
                                  </span>
                                }
                                </>
                              }
                              menuElem={
                                file.to && <MenuContextList
                                  actions={getFileActions(file, toTemplateEnvironment?.template)}
                                  handleDelete="false"
                                  refContainer={refMenuContainerTo}
                                />
                              }
                            />
                          ))}
                        </TwTable.Body>
                      </TwTable>
                      </div>
                    </TwTableContext.Provider>
                  </CmpPanel>
                </div>
              </div>
            </EnvironmentGroupContainerCard>

            <div className='flex mt-8'>
              <div className='flex-1' />
              <CmpButton
                variant={CmpButton.variants.primary}
                // className="flex-none w-32 bg-white text-gray-900 hover:bg-gray-100 hover:bg-text-white dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                //disabled={selectedFiles.length === 0}
                onClick={() => history.goBack()}
              >
                Back
              </CmpButton>
            </div>
          </>
        )}
      </TwPage>
      
      <FileInfoDialog
        fileInfo={fileInfoData}
        openShowFileInfo = {openShowFileInfo}
        setOpenShowFileInfo = {setOpenShowFileInfo}
        hasCrudAccess = {hasCrudAccess}
        loadBaseData = {loadBaseData}
      />

      {/* Delete Dialog */}
      <GenericModal open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation}
        onConfirm={handleDelete}
        onCancel={() => {
          setOpenDeleteConfirmation(false);
          setFileToDelete(null);
        }}
        showCancelButton={true}
        confirmButtonText={'Delete'}
        title={`Delete Interact Template Environment File: ${fileToDelete?.fileName || ""}`}>
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete the selected Interact Template File?
          </p>
        </div>
      </GenericModal>

      <CopyToLocalGroupModal title={'Copy file to Local Environment Group'} modalHelper={copyToLocalGroupModalHelper} onConfirm={handleCopyToLocalGroup} isCopying={isCopying} />
    </>
  );
}

function CopyToLocalGroupModal({ modalHelper, title, onConfirm, isCopying }) {
  const { classNames } = useClassNames();

  const [localEnvGroups, setLocalEnvGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [busyMessage, setBusyMessage] = useState(false);

  useEffect(() => {
    setIsBusy(isCopying);
    setBusyMessage(isCopying ? `Copying file to local environment group ${selectedGroup.name}...` : null);
  }, [isCopying]);

  useEffect(async () => {
    if (modalHelper.activeItem) {
      setBusyMessage("Loading local environments...");
      setIsBusy(true);

      try {
        let envGrps = await InteractApi.getEnvironmentGroupsByCustomerId();
        envGrps = envGrps.filter(g => !g.isVirtual && g.id !== modalHelper.activeItem.environmentGroup.id)
        setLocalEnvGroups(envGrps);
      } finally {
        setIsBusy(false);
        setBusyMessage(null);
      }
    }
  }, [modalHelper.activeItem])

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={() => { modalHelper.close() }}
      size={'large'}
      title={title}
    >
      { isBusy && (
        <>
          <div className="flex justify-center">
            <FontAwesomeIcon icon={faSpinner} className="text-white" size="4x" spin bounce />
          </div>
          <div className="flex justify-center mt-4">
            <CmpLabel text={busyMessage} />
          </div>
        </>
      )}

      { !isBusy && (
        <TwTable>
          <TwTable.Head>
            <TwTable.Header additionalClass={'sm:pl-6 h-14 uppercase'}>
              Name
            </TwTable.Header>
          </TwTable.Head>
          <TwTable.Body additionalClass='text-left'>
            {!!localEnvGroups && localEnvGroups.map((item, idx) => (
              <TwTable.BodyRow key={`ff${item.id}-m`} additionalClass={classNames(
                item.id === selectedGroup?.id
                  ? "bg-brand-blue text-white"
                  : "",
                  "h-12 hover:bg-gray-100 cursor-pointer"
                )}
              >
                <TwTable.BodyCol onClick={() => setSelectedGroup(item)}>
                  {item.name}
                </TwTable.BodyCol>
              </TwTable.BodyRow>
            ))}
          </TwTable.Body>
        </TwTable>
      )}

      <div className="flex justify-end mt-6 sm:mt-8 space-x-2">
        <CmpButton
          variant={CmpButton.variants.secondary}
          className="justify-center"
          disabled={false}
          onClick={() => {
            modalHelper.close();
          }}
        >
          Cancel
        </CmpButton>
        <CmpButton
          variant={CmpButton.variants.primary}
          className="justify-center"
          disabled={isBusy || !selectedGroup}
          type={"submit"}
          onClick={() => {
            if (selectedGroup)
              onConfirm(modalHelper.activeItem.template, modalHelper.activeItem.file, selectedGroup)
          }}
        >
          Confirm
        </CmpButton>
      </div>
    </Modal>
  )
}
